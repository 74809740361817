<template>
  <div class="overflow-hidden bg-white py-32">
    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
        <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
          <h2 class="text-3xl font-bold tracking-tight text-left text-gray-900 sm:text-4xl">Teaching at PVIS</h2>
          <p class="mt-6 text-xl text-left leading-8 text-gray-600">{{ content.employmentTitle }}</p>
          <div class="mt-6 text-base text-left leading-7 text-gray-600 rich-text-content" v-if="content.employmentOpportunities" v-html="renderRichText(content.employmentOpportunities)"></div>

          <p class="mt-6 font-bold text-base text-left leading-7 text-gray-600">{{ content.qualificationsTitle }}</p>

          <div class="mt-6 text-base text-left leading-7 text-gray-600 rich-text-content" v-if="content.qualifications" v-html="renderRichText(content.qualifications)"></div>

        <p class="mt-6 text-base text-left leading-7 text-gray-600">Please get in touch if you are available for work and are a qualified teacher: employment@pvis.edu.vu </p>
<div class="mt-10 flex">
            <a href="mailto:employment@pvis.edu.vu?subject=Join Our Team" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Join our team <span aria-hidden="true">&rarr;</span></a>
          </div>

          <div class="lg:col-span-1 lg:w-full lg:pb-8 lg:pr-6 mt-8">
        <img 
          v-if="employmentPosterUrl" 
          :src="employmentPosterUrl" 
          alt="Employment Poster"
          class="w-full rounded-lg shadow-lg object-cover"
        />
      </div>

        </div>

        

        
        <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
          <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
            <img :src="pvis3" alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
          </div>
          <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
            <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
              <img :src="pvis2" alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img :src="pvis1" alt="" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
              <img :src="pvis4" alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
            </div>
          </div>
        </div>
       
        
      </div>

    </div>

    <div class="mx-auto max-w-7xl px-6 lg:grid lg:grid-cols-2 lg:px-8 flex flex-wrap">

      
    </div>
  </div>
</template>



<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import PVIS1 from '@/assets/documents/PVIS1.jpg';
import PVIS2 from '@/assets/documents/PVIS2.jpg';
import PVIS3 from '@/assets/documents/PVIS3.jpg';
import PVIS4 from '@/assets/documents/PVIS4.jpg';

const pvis1 = ref(PVIS1);
const pvis2 = ref(PVIS2);
const pvis3 = ref(PVIS3);
const pvis4 = ref(PVIS4);

const content = ref({
  employmentTitle: '',
  employmentOpportunities: null,
  qualificationsTitle: '',
  qualifications: null,
  employmentPoster: null
});

const employmentPosterUrl = ref('');

const renderRichText = (richTextContent) => {
  if (!richTextContent) return '';
  return documentToHtmlString(richTextContent);
};

const fetchContent = async () => {
  try {
    const response = await axios.get(
      `https://cdn.contentful.com/spaces/8ayqhzh3p1ua/environments/master/entries?access_token=${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}&content_type=employment`
    );
    
    if (response.data.items && response.data.items.length > 0) {
      const fields = response.data.items[0].fields;
      content.value = {
        employmentTitle: fields.employmentTitle || '',
        employmentOpportunities: fields.employmentOpportunities || null,
        qualificationsTitle: fields.qualificationsTitle || '',
        qualifications: fields.qualifications || null,
        employmentPoster: fields.employmentPoster || null
      };

      // Get the employment poster URL from assets
      if (fields.employmentPoster && fields.employmentPoster.sys) {
        const assetId = fields.employmentPoster.sys.id;
        const asset = response.data.includes?.Asset?.find(
          (asset) => asset.sys.id === assetId
        );
        if (asset) {
          employmentPosterUrl.value = asset.fields.file.url;
          // Ensure the URL is HTTPS
          if (employmentPosterUrl.value.startsWith('//')) {
            employmentPosterUrl.value = 'https:' + employmentPosterUrl.value;
          }
        }
      }
    } else {
      console.error('No content found in Contentful');
    }
  } catch (error) {
    console.error('Error fetching content from Contentful:', error);
  }
};

onMounted(() => {
  fetchContent();
});
</script>

<style>
.rich-text-content p {
  margin-bottom: 1rem;
}

.rich-text-content ul {
  margin: 1rem 0;
  padding-left: 1.5rem;
  list-style-type: disc;
}

.rich-text-content ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
  list-style-type: decimal;
}

.rich-text-content ul ul {
  list-style-type: circle;
}

.rich-text-content ul ul ul {
  list-style-type: square;
}

.rich-text-content li {
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.rich-text-content h1,
.rich-text-content h2,
.rich-text-content h3,
.rich-text-content h4,
.rich-text-content h5,
.rich-text-content h6 {
  margin: 1.5rem 0 1rem 0;
  font-weight: bold;
}

.rich-text-content blockquote {
  border-left: 4px solid #e5e7eb;
  padding-left: 1rem;
  margin: 1rem 0;
  font-style: italic;
}

.rich-text-content a {
  color: #4f46e5;
  text-decoration: underline;
}

.rich-text-content a:hover {
  color: #4338ca;
}
</style>