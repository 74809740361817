<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="w-full py-2">
      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-2xl font-bold text-gray-900">Student Billing Management</h1>
          <div class="mt-1 text-sm text-gray-500 space-y-1">
            <div class="flex items-center gap-2">
              <span>Displaying {{ displayedCounts.families }} {{ displayedCounts.families === 1 ? 'family' : 'families' }}</span>
              <span class="text-gray-300">|</span>
              <span>{{ displayedCounts.students }} {{ displayedCounts.students === 1 ? 'student' : 'students' }}</span>
              <span class="text-gray-300">|</span>
              <span>Total Yearly Fees: {{ new Intl.NumberFormat('en-VU', { style: 'currency', currency: 'VUV' }).format(totalYearlyFees) }}</span>
              <span v-if="isLoading" class="ml-2 inline-flex items-center">
                <svg class="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span class="ml-1">Loading...</span>
              </span>
            </div>
            <div class="text-xs text-gray-400">
              Total database: {{ totalStudents }} students
            </div>
             <!-- Updated Settings Info -->
             <div v-if="settings" class="text-center text-xs text-gray-500 mt-2">
              <span>Payment Term: {{ settings.paymentTerm }} days</span>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <XeroConnect v-once />
          <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
        </div>
      </div>


      <!-- Search and Filters -->
      <div class="mt-4 w-full">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <!-- Search input - Full width on mobile, 1/3 on desktop -->
          <div class="w-full space-y-4">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search by Hero ID, Name, Family Code, or Year Level..."
              class="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
            <select
              v-model="selectedYear"
              class="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">All Year Levels</option>
              <option v-for="year in yearLevels" :key="year" :value="year">{{ year }}</option>
            </select>
          </div>

          <!-- Filter checkboxes - Full width on mobile, 2/3 on desktop -->
          <div class="lg:col-span-2 w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
            <div>
              <h4 class="font-medium text-gray-700 mb-2">Status Filters</h4>
              <div class="space-y-2">
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.showWithdrawn" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Show Withdrawn</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.showAlumni" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Show Alumni</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.familyOfFourPlus" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Family of 4+</span>
                </label>
              </div>
            </div>

            <div>
              <h4 class="font-medium text-gray-700 mb-2">Student Type</h4>
              <div class="space-y-2">
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.staffChild" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Staff Child</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.corporate" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Corporate</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.bursary" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Bursary</span>
                </label>
              </div>
            </div>

            <div>
              <h4 class="font-medium text-gray-700 mb-2">Fee Components</h4>
              <div class="space-y-2">
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.missingFeeComponent" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Missing Fee Component</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.fullELP" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Full ELP</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.fiveMornings" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Five Mornings</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.threeMornings" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Three Mornings</span>
                </label>
              </div>
            </div>

            <div>
              <h4 class="font-medium text-gray-700 mb-2">Missing Invoices</h4>
              <div class="space-y-2">
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.missingT1Invoice" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Missing Term 1 Invoice</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.missingT2Invoice" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Missing Term 2 Invoice</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.missingT3Invoice" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Missing Term 3 Invoice</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.missingT4Invoice" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Missing Term 4 Invoice</span>
                </label>
              </div>
            </div>

            <div>
              <h4 class="font-medium text-gray-700 mb-2">Additional Programs</h4>
              <div class="space-y-2">
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.littleExplorer" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">Little Explorer</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.saceLevy1" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">SACE Levy 1</span>
                </label>
                <label class="flex items-center gap-2">
                  <input type="checkbox" v-model="filters.saceLevy2" class="rounded text-indigo-600">
                  <span class="text-sm text-gray-600">SACE Levy 2</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Grid of Family Cards -->
      <div class="mt-8 grid grid-cols-1 gap-3">
        <div v-for="family in families" :key="family.code" class="bg-white overflow-hidden shadow rounded-lg border border-black">
          <!-- Family Header -->
          <div class="px-4 py-2">
            <div class="flex justify-between items-center">
              <div class="flex items-center gap-2">
                <div>
                  <h3 class="text-xl font-bold leading-6 text-gray-900">
                    Family <button 
                      @click="filterByFamilyCode(family.code)"
                      class="hover:text-indigo-600 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >{{ family.code }}</button>
                  </h3>
                </div>
                <div v-if="family.students.some(student => student.staffChild)" 
                     class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xl font-semibold text-red-700 ring-1 ring-inset ring-red-600/20">
                  Staff Family
                </div>
              </div>
               <!-- Compact Invoicing History -->
               <div class="flex items-center space-x-4">
                    <template v-for="year in invoiceYears" :key="year">
                      <template v-for="term in [1,2,3,4]" :key="`${year}-${term}`">
                        <div class="flex items-center space-x-2">
                          <div class="text-xs font-medium text-gray-500">T{{ term }} {{ year }}</div>
                          <a v-if="hasInvoiceForTerm(family, term, year)"
                             :href="`https://go.xero.com/app/!z2WTp/invoicing/edit/${family.students.find(s => s.Invoices && s.Invoices[`Term${term}${year}Invoice`])?.Invoices[`Term${term}${year}Invoice`]}`"
                             target="_blank"
                             class="text-green-500 hover:text-green-600">
                            <svg class="h-5 w-5" 
                                 viewBox="0 0 24 24" 
                                 fill="currentColor">
                              <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                            </svg>
                          </a>
                          <svg v-else 
                               class="h-5 w-5 text-red-500" 
                               viewBox="0 0 24 24" 
                               fill="currentColor">
                            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div v-if="!(year === invoiceYears[0] && term === 4)" class="h-8 w-px bg-gray-200"></div>
                      </template>
                    </template>
                  </div>
                <!-- student count per family -->
              <span class="text-lg font-bold text-gray-500">{{ family.students.length }} Students</span>
            </div>
          </div>

          <!-- Family Content -->
          <div class="p-2">
            <!-- Grid container for students and fees -->
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
              <!-- Students List -->
              <div>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-3">
                  <div v-for="student in family.students" :key="student._id" class="flex items-center justify-between p-2 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer ring-1 ring-inset"
                       @click="openDialog(student)" :class="{
                            'bg-purple-50 text-purple-700 ring-purple-600': getStudentStatus(student, family.students) === 'Corporate',
                            'bg-green-50 text-green-700 ring-green-600': getStudentStatus(student, family.students) === 'Citizen/Long-Term',
                            'bg-yellow-50 text-yellow-700 ring-yellow-600': getStudentStatus(student, family.students) === 'Non-Citizen',
                            'bg-red-50 text-red-700 ring-red-600': ['EEC', 'Senior'].includes(getSchoolLevel(student.currentYear)) && !student.feeComponent
                          }">
                    <div class="flex flex-col w-full" >
                        <div class="flex flex-col">
                          <div class="inline-flex items-center text-xs font-medium mb-1">
                            {{ getStudentStatus(student, family.students) }}
                            <div class="flex gap-2 ml-2">
                              <span v-if="student.saceLevy1paid" class="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                SACE Levy 1 Paid
                              </span>
                              <span v-if="student.saceLevy2paid" class="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                SACE Levy 2 Paid
                              </span>
                              <span v-if="student.littleExplorer" class="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                Little Explorer
                              </span>
                            </div>
                          </div>
                          <div class="grid">
                            <div class="flex items-center gap-x-1">
                              <div class="text-lg font-medium text-gray-900">[{{ student.heroId }}]</div>
                              <div class="text-lg font-medium text-gray-900">{{ student.firstNameLegal }} {{ student.lastNameLegal }} | <span class="font-bold">{{ student.currentYear }}</span> ({{ student.academicYear }})</div>
                            </div>
                            <div class="text-sm text-left text-gray-900">{{ getSchoolLevel(student.currentYear) }}
                            <span v-if="student.currentYear === 'PRE'" class="font-bold">
                              | Fee Component: {{ student.feeComponent || 'Not Set' }}
                            </span>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Fee Calculation -->
              <div>
                <div v-if="settings" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                  <FeeCalculator
                    :students="family.students"
                    :family-code="family.code"
                    :settings="settings"
                    :invoices="familyInvoices[family.code] || {}"
                    :generating-invoices="generatingInvoices.get(family.code)"
                    @invoice-generated="(data) => handleInvoiceGenerated(family.code, data)"
                    @invoice-voided="(data) => handleInvoiceVoided(family.code, data)"
                  />
                </div>
                <div v-else class="text-center p-4 text-gray-500">Loading settings...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Keep the existing modal/dialog component -->
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" :open="isOpen" @close="isOpen = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle v-if="selectedStudent" class="text-base font-semibold leading-6 text-gray-900">{{ selectedStudent.firstNameLegal }} {{ selectedStudent.lastNameLegal }}</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="isOpen = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <!-- Your content -->
                    <template v-if="selectedStudent">
                      <form @submit.prevent="updateStudent">
                        <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label class="block text-sm font-medium text-gray-700">
                        First Name
                        <input v-model="selectedStudent.firstNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Middle Names
                        <input v-model="selectedStudent.middleNames" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Last Name
                        <input v-model="selectedStudent.lastNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Hero ID
                        <input v-model="selectedStudent.heroId" type="number" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        First Attendance Date
                        <input v-model="formattedFirstAttendanceDate" type="date" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Current Year
                        <select v-model="selectedStudent.currentYear" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <option value="PRE">PRE</option>
                          <option value="FND">FND</option>
                          <option value="Year 1">Year 1</option>
                          <option value="Year 2">Year 2</option>
                          <option value="Year 3">Year 3</option>
                          <option value="Year 4">Year 4</option>
                          <option value="Year 5">Year 5</option>
                          <option value="Year 6">Year 6</option>
                          <option value="Year 7">Year 7</option>
                          <option value="Year 8">Year 8</option>
                          <option value="Year 9">Year 9</option>
                          <option value="Year 10">Year 10</option>
                          <option value="Year 11">Year 11</option>
                          <option value="Year 12">Year 12</option>
                          <option value="Alumni">Alumni</option>
                        </select>
                      </label>
                      </div>

                      <div v-if="selectedStudent?.currentYear === 'PRE'" class="col-span-6 sm:col-span-6">
                        <label for="feeComponent" class="block text-sm font-medium text-gray-700">Fee Component</label>
                        <select id="feeComponent" v-model="selectedStudent.feeComponent" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                          <option value="">Select Fee Component</option>
                          <option value="Full ELP">Full ELP Program</option>
                          <option value="Five Mornings">Five Mornings</option>
                          <option value="Three Mornings">Three Mornings</option>
                        </select>
                      </div>

                      <!-- Little Explorer Checkbox (only for Five Mornings or Three Mornings) -->
                      <div v-if="['Five Mornings', 'Three Mornings'].includes(selectedStudent?.feeComponent)" class="col-span-6 sm:col-span-6">
                        <div class="relative flex items-start">
                          <div class="flex h-5 items-center">
                            <input id="littleExplorer" v-model="selectedStudent.littleExplorer" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="littleExplorer" class="font-medium text-gray-700">Little Explorer Program</label>
                            <p class="text-gray-500">Additional fee of 32,455 VUV per term</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Discounts / Rebates
                        </label>
                        <div class="mt-2 space-y-4 border border-gray-200 rounded-md p-4">
                          <fieldset>
                            <legend class="text-sm font-medium text-gray-600">Staff Discount</legend>
                            <div class="mt-2 space-y-2">
                              <div class="relative flex items-start">
                                <div class="flex h-6 items-center">
                                  <input id="staffNone" name="staffDiscountType" type="radio" :checked="!selectedStudent.staffChildOneParent && !selectedStudent.staffChildTwoParents" @change="setStaffDiscount(null)" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label for="staffNone" class="font-medium text-gray-900">None</label>
                                </div>
                              </div>
                              <div class="relative flex items-start">
                                <div class="flex h-6 items-center">
                                  <input id="staffOneParent" name="staffDiscountType" type="radio" :checked="selectedStudent.staffChildOneParent" @change="setStaffDiscount('one')" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label for="staffOneParent" class="font-medium text-gray-900">Staff Discount (1 Parent)</label>
                                </div>
                              </div>
                              <div class="relative flex items-start">
                                <div class="flex h-6 items-center">
                                  <input id="staffTwoParents" name="staffDiscountType" type="radio" :checked="selectedStudent.staffChildTwoParents" @change="setStaffDiscount('two')" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label for="staffTwoParents" class="font-medium text-gray-900">Staff Discount (2 Parents)</label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset>
                            <legend class="text-sm font-medium text-gray-600">Bursary / Scholarship</legend>
                            <div class="mt-2 space-y-2">
                               <div class="relative flex items-start">
                                <div class="flex h-6 items-center">
                                  <input id="rebateNone" name="rebateType" type="radio" :checked="!selectedStudent.isBursary && !selectedStudent.isScholarship" @change="setRebate(null)" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label for="rebateNone" class="font-medium text-gray-900">None</label>
                                </div>
                              </div>
                              <div class="relative flex items-start">
                                <div class="flex h-6 items-center">
                                  <input id="rebateBursary" name="rebateType" type="radio" :checked="selectedStudent.isBursary" @change="setRebate('bursary')" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label for="rebateBursary" class="font-medium text-gray-900">Bursary</label>
                                </div>
                              </div>
                              <div class="relative flex items-start">
                                <div class="flex h-6 items-center">
                                  <input id="rebateScholarship" name="rebateType" type="radio" :checked="selectedStudent.isScholarship" @change="setRebate('scholarship')" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label for="rebateScholarship" class="font-medium text-gray-900">Scholarship</label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <div>
                            <input type="checkbox" v-model="selectedStudent.debenturesCorp" id="debenturesCorp" />
                            <label for="debenturesCorp" class="ml-2 text-sm font-medium text-gray-700">Debentures or Corporate</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Enrollment Status
                          <select v-model="selectedStudent.status" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option value="Enrolled">Enrolled</option>
                            <option value="Withdrawn">Withdrawn</option>
                          </select>
                        </label>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Family Code
                        <input v-model="selectedStudent.familyCode" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Primary Citizenship
                        <input v-model="selectedStudent.primaryCitizenship" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 First Name
                        <input v-model="selectedStudent.contact1FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Last Name
                        <input v-model="selectedStudent.contact1LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Email
                        <input v-model="selectedStudent.contact1Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 First Name
                        <input v-model="selectedStudent.contact2FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Last Name
                        <input v-model="selectedStudent.contact2LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Email
                        <input v-model="selectedStudent.contact2Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      </div>
                      
                    </form>
                  </template>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" @click="closeDialog">Cancel</button>
<button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500" @click="saveData">Save</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref, computed, watch } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import FeeCalculator from '../components/FeeCalculator.vue';
import { getSchoolLevel, getStudentStatus } from '../utils/feeUtils.js';
import XeroConnect from '../components/XeroConnect.vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

/**
 * @typedef {Object} Student
 * @property {string} _id
 * @property {string} heroId
 * @property {string} firstNameLegal
 * @property {string} lastNameLegal
 * @property {string} [middleNames]
 * @property {string} currentYear
 * @property {string} academicYear
 * @property {string} familyCode
 * @property {string} feeComponent
 * @property {string} firstAttendanceDate
 * @property {string} primaryCitizenship
 * @property {boolean} debenturesCorp
 * @property {boolean} staffChild
 * @property {boolean} bursary
 * @property {string} [contact1FirstName]
 * @property {string} [contact1LastName]
 * @property {string} [contact1Email]
 * @property {string} [contact2FirstName]
 * @property {string} [contact2LastName]
 * @property {string} [contact2Email]
 * @property {Object.<string, string>} [Invoices]
 */

/**
 * @typedef {Object} Family
 * @property {string} code
 * @property {Student[]} students
 */

// --- State ---
const isOpen = ref(false);
const selectedStudent = ref(null);
const families = ref([]);
const studentsData = ref([]);
const familyInvoices = ref({});
const searchQuery = ref('');
const filters = ref({
  showWithdrawn: false,
  showAlumni: false,
  familyOfFourPlus: false,
  staffChild: false,
  corporate: false,
  bursary: false,
  fullELP: false,
  fiveMornings: false,
  threeMornings: false,
  littleExplorer: false,
  saceLevy1: false,
  saceLevy2: false,
  missingFeeComponent: false,
  missingT1Invoice: false,
  missingT2Invoice: false,
  missingT3Invoice: false,
  missingT4Invoice: false
});
const selectedYear = ref('');
const yearLevels = [
  'PRE',
  'FND',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Year 7',
  'Year 8',
  'Year 9',
  'Year 10',
  'Year 11',
  'Year 12',
  'Alumni'
];
const isLoading = ref(false);
const settings = ref(null);
const generatingInvoices = ref(new Map());
const isInitialized = ref(false);

// --- Computed Properties ---

const invoiceYears = computed(() => {
  const currentYear = new Date().getFullYear();
  return [currentYear, currentYear - 1];
});

const formattedFirstAttendanceDate = computed({
  get: () => {
    if (!selectedStudent.value?.firstAttendanceDate) return '';
    const date = new Date(selectedStudent.value.firstAttendanceDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
  set: (value) => {
    if (selectedStudent.value) {
      selectedStudent.value.firstAttendanceDate = new Date(value).toISOString();
    }
  }
});

const totalStudents = computed(() => {
  return studentsData.value.filter(student => 
    (!filters.value.showWithdrawn && student.status !== 'Withdrawn') && 
    (!filters.value.showAlumni && student.currentYear !== 'Alumni')
  ).length;
});

const displayedCounts = computed(() => {
  return {
    families: families.value.length,
    students: families.value.reduce((total, family) => total + family.students.length, 0)
  };
});

const totalYearlyFees = computed(() => {
  if (!settings.value || !settings.value.fees) return 0;
  const currentSettings = settings.value;

  return families.value.reduce((total, family) => {
    let familySubtotal = family.students.reduce((subtotal, student) => {
      return subtotal + calculateBillingYearlyFee(student, currentSettings);
    }, 0);

    if (family.students.length >= 4 && currentSettings.fees.discounts) {
      const discountRate = typeof currentSettings.fees.discounts.familySize === 'number' && currentSettings.fees.discounts.familySize >= 0 && currentSettings.fees.discounts.familySize <= 1
                         ? currentSettings.fees.discounts.familySize
                         : 0;
      familySubtotal *= (1 - discountRate);
    }

    return total + Math.round(familySubtotal);
  }, 0);
});

// --- Calculation Logic ---

const calculateBillingYearlyBaseTuition = (student, currentSettings) => {
  if (!currentSettings || !currentSettings.fees) return 0;
  const localFees = currentSettings.fees;
  let fee = 0;
  const schoolLevel = getSchoolLevel(student.currentYear);
  const family = families.value.find(f => f.code === student.familyCode);
  const familyStudents = family ? family.students : [student];
  const status = getStudentStatus(student, familyStudents);

  if (schoolLevel === 'EEC') {
    switch (student.feeComponent) {
      case 'Full ELP': fee = localFees.eec.fullElp; break;
      case 'Five Mornings': fee = localFees.eec.fiveMornings; break;
      case 'Three Mornings': fee = localFees.eec.threeMornings; break;
      default: fee = 0;
    }
  } else {
    switch (schoolLevel) {
      case 'Junior':
        fee = status === 'Corporate' ? localFees.junior.corporate : 
              status === 'Non-Citizen' ? localFees.junior.nonCitizen : 
              localFees.junior.citizen;
        break;
      case 'Middle':
        fee = status === 'Corporate' ? localFees.middle.corporate : 
              status === 'Non-Citizen' ? localFees.middle.nonCitizen : 
              localFees.middle.citizen;
        break;
      case 'Senior':
        fee = status === 'Corporate' ? localFees.senior.corporate : 
              status === 'Non-Citizen' ? localFees.senior.nonCitizen : 
              localFees.senior.citizen;
        break;
      default: fee = 0;
    }
  }
  return Math.round(fee);
};

const calculateBillingYearlyFee = (student, currentSettings) => {
  if (!currentSettings || !currentSettings.fees) return 0;
  const localFees = currentSettings.fees;
  
  let baseFee = calculateBillingYearlyBaseTuition(student, currentSettings);
  let additions = 0;

  if (student.saceLevy1) additions += localFees.saceLevy.levy1;
  else if (student.saceLevy2) additions += localFees.saceLevy.levy2;

  const family = families.value.find(f => f.code === student.familyCode);
  const familyStudents = family ? family.students : [student]; 
  const status = getStudentStatus(student, familyStudents);

  if (status === 'Corporate') {
      additions += localFees.buildingLevy.corporate;
  }
  
  if (student.littleExplorer) {
      additions += localFees.littleExplorer.perTerm * 4;
  }

  let totalFee = baseFee + additions;
  let discountRate = 0;

  // Determine highest discount rate
  if (student.isScholarship) {
      discountRate = localFees.discounts.scholarshipDiscount;
  } else if (student.isBursary) {
      discountRate = localFees.discounts.bursaryDiscount;
  } else if (student.staffChildTwoParents) { 
      discountRate = localFees.discounts.staffDiscountTwoParents;
  } else if (student.staffChildOneParent) { 
      discountRate = localFees.discounts.staffDiscountOneParent;
  } else if (student.staffChild) { // Fallback?
      discountRate = localFees.discounts.staffDiscountOneParent; 
  }

  discountRate = typeof discountRate === 'number' && discountRate >= 0 && discountRate <= 1 ? discountRate : 0;
  totalFee *= (1 - discountRate);

  return Math.round(totalFee);
};

// --- Methods ---

const openDialog = (student) => {
  selectedStudent.value = student;
  isOpen.value = true;
};

const saveData = async () => {
  if (!selectedStudent.value) return;
  try {
    await axios.put('/api/updateStudent', selectedStudent.value);
    // Instead of re-fetching all, just update the local student data
    const index = studentsData.value.findIndex(s => s._id === selectedStudent.value._id);
    if (index !== -1) {
      studentsData.value[index] = { ...selectedStudent.value };
    }
    organizeStudentsByFamily(); // Re-filter/organize based on potentially changed data
    isOpen.value = false;
  } catch (error) {
    console.error('Error updating student:', error);
  }
};

const closeDialog = () => {
  isOpen.value = false;
};

const fetchStudents = async () => {
  isLoading.value = true; // Keep loading indicator tied to student fetch
  try {
    const response = await axios.get('/api/getStudents');
    studentsData.value = response.data;
  } catch (error) {
    console.error('Error fetching students:', error);
    studentsData.value = []; // Ensure it's an array on error
  } finally {
    isLoading.value = false;
  }
};

const fetchSettings = async () => {
  try {
    const response = await axios.get('/api/getSettings');
    settings.value = response.data;
    console.log('Settings fetched in BillingYearly:', settings.value);
  } catch (error) {
    console.error('Error fetching settings in BillingYearly:', error);
    settings.value = null; // Handle error state
  }
};

const organizeStudentsByFamily = () => {
  const familyMap = new Map();

  if (!studentsData.value || studentsData.value.length === 0) {
    families.value = [];
    return;
  }
  
  const filteredStudents = studentsData.value.filter(student => {
    if (selectedYear.value && student.currentYear !== selectedYear.value) return false;

    if (filters.value.showWithdrawn) {
      if (student.status !== 'Withdrawn') return false;
    } else if (filters.value.showAlumni) {
      if (student.currentYear !== 'Alumni') return false;
    } else {
      if (student.status === 'Withdrawn' || student.currentYear === 'Alumni') return false;
    }

    if (filters.value.staffChild && !student.staffChild) return false;
    if (filters.value.corporate && !student.debenturesCorp) return false;
    if (filters.value.bursary && !student.bursary) return false;

    if (filters.value.missingFeeComponent) {
      const schoolLevel = getSchoolLevel(student.currentYear);
      const needsFeeComponent = ['EEC', 'Senior'].includes(schoolLevel);
      if (!needsFeeComponent || student.feeComponent) return false;
    }

    if (filters.value.fullELP && student.feeComponent !== 'Full ELP') return false;
    if (filters.value.fiveMornings && student.feeComponent !== 'Five Mornings') return false;
    if (filters.value.threeMornings && student.feeComponent !== 'Three Mornings') return false;

    if (filters.value.littleExplorer && !student.littleExplorer) return false;
    if (filters.value.saceLevy1 && student.feeComponent !== 'SACE Levy 1') return false;
    if (filters.value.saceLevy2 && student.feeComponent !== 'SACE Levy 2') return false;

    if (searchQuery.value) {
      const query = searchQuery.value.toLowerCase();
      return (
        student.heroId?.toString().includes(query) ||
        student.firstNameLegal?.toLowerCase().includes(query) ||
        student.lastNameLegal?.toLowerCase().includes(query) ||
        student.familyCode?.toLowerCase().includes(query) ||
        student.currentYear?.toLowerCase().includes(query)
      );
    }

    return true;
  });

  filteredStudents.forEach(student => {
    const { familyCode } = student;
    if (!familyCode) return;

    if (!familyMap.has(familyCode)) {
      familyMap.set(familyCode, {
        code: familyCode,
        students: [],
      });
    }
    const family = familyMap.get(familyCode);
    if (family) {
      family.students.push(student);
    }
  });

  let familiesArray = Array.from(familyMap.values());
  
  if (filters.value.missingT1Invoice || filters.value.missingT2Invoice || 
      filters.value.missingT3Invoice || filters.value.missingT4Invoice) {
    familiesArray = familiesArray.filter(family => {
      const currentYear = new Date().getFullYear();
      if (filters.value.missingT1Invoice && !hasInvoiceForTerm(family, 1, currentYear)) return true;
      if (filters.value.missingT2Invoice && !hasInvoiceForTerm(family, 2, currentYear)) return true;
      if (filters.value.missingT3Invoice && !hasInvoiceForTerm(family, 3, currentYear)) return true;
      if (filters.value.missingT4Invoice && !hasInvoiceForTerm(family, 4, currentYear)) return true;
      return false;
    });
  }

  if (filters.value.familyOfFourPlus) {
    familiesArray = familiesArray.filter(family => family.students.length >= 4);
  }

  families.value = familiesArray.sort((a, b) => 
    a.code.localeCompare(b.code)
  );
};

const hasInvoiceForTerm = (family, term, year) => {
  return family.students.some(student => 
    student.Invoices && 
    student.Invoices[`Term${term}${year}Invoice`] && 
    student.Invoices[`Term${term}${year}Invoice`].length > 0
  );
};

const handleInvoiceGenerated = async (familyCode, data) => {
  try {
    generatingInvoices.value.set(familyCode, true);
    
    await axios.post('/api/updateFamilyInvoice', {
      familyCode,
      term: data.term,
      year: new Date().getFullYear(),
      invoice_id: data.invoice_id
    });

    const family = families.value.find(f => f.code === familyCode);
    if (family) {
      const invoiceField = `Term${data.term}${new Date().getFullYear()}Invoice`;
      family.students.forEach(student => {
        if (!student.Invoices) {
          student.Invoices = {};
        }
        student.Invoices[invoiceField] = data.invoice_id;
      });
    }
  } catch (error) {
    console.error('Error generating invoice:', error);
  } finally {
    generatingInvoices.value.delete(familyCode);
  }
};

const filterByFamilyCode = (familyCode) => {
  Object.keys(filters.value).forEach(key => filters.value[key] = false);
  selectedYear.value = '';
  searchQuery.value = familyCode;
};

const initializeFromQuery = () => {
  const query = route.query;
  searchQuery.value = query.search || '';
  selectedYear.value = query.year || '';
  filters.value = {
    showWithdrawn: query.showWithdrawn === 'true',
    showAlumni: query.showAlumni === 'true',
    familyOfFourPlus: query.familyOfFourPlus === 'true',
    staffChild: query.staffChild === 'true',
    corporate: query.corporate === 'true',
    bursary: query.bursary === 'true',
    fullELP: query.fullELP === 'true',
    fiveMornings: query.fiveMornings === 'true',
    threeMornings: query.threeMornings === 'true',
    littleExplorer: query.littleExplorer === 'true',
    saceLevy1: query.saceLevy1 === 'true',
    saceLevy2: query.saceLevy2 === 'true',
    missingFeeComponent: query.missingFeeComponent === 'true',
    missingT1Invoice: query.missingT1Invoice === 'true',
    missingT2Invoice: query.missingT2Invoice === 'true',
    missingT3Invoice: query.missingT3Invoice === 'true',
    missingT4Invoice: query.missingT4Invoice === 'true'
  };
};

const updateQueryParams = () => {
  const query = {};
  if (searchQuery.value) query.search = searchQuery.value;
  if (selectedYear.value) query.year = selectedYear.value;
  Object.entries(filters.value).forEach(([key, value]) => {
    if (value === true) query[key] = 'true';
  });
  router.replace({ query });
};

watch([searchQuery, filters, selectedYear], () => {
  if (!isInitialized.value) return;
  organizeStudentsByFamily();
  updateQueryParams();
}, { deep: true });

watch(() => route.query, (newQuery, oldQuery) => {
  if (!isInitialized.value || JSON.stringify(newQuery) === JSON.stringify(oldQuery)) return;
  initializeFromQuery();
  organizeStudentsByFamily();
}, { deep: true });

watch(
  () => selectedStudent.value?.feeComponent,
  (newFeeComponent) => {
    if (selectedStudent.value && newFeeComponent === 'Full ELP') {
      selectedStudent.value.littleExplorer = false;
    }
  }
);

watch(
  () => selectedStudent.value?.saceLevy1,
  (newValue) => {
    if (selectedStudent.value && newValue) {
      selectedStudent.value.saceLevy2 = false;
    }
  }
);

watch(
  () => selectedStudent.value?.saceLevy2,
  (newValue) => {
    if (selectedStudent.value && newValue) {
      selectedStudent.value.saceLevy1 = false;
    }
  }
);

// Add helper methods to handle radio button logic
const setStaffDiscount = (type) => {
  if (!selectedStudent.value) return;
  selectedStudent.value.staffChildOneParent = type === 'one';
  selectedStudent.value.staffChildTwoParents = type === 'two';
  // Ensure old staffChild flag is unset if necessary
  if (type !== null) selectedStudent.value.staffChild = true; 
  else selectedStudent.value.staffChild = false; 
};

const setRebate = (type) => {
  if (!selectedStudent.value) return;
  selectedStudent.value.isBursary = type === 'bursary';
  selectedStudent.value.isScholarship = type === 'scholarship';
  // Ensure old bursary flag is handled
  if (type === 'bursary') selectedStudent.value.bursary = true;
  else selectedStudent.value.bursary = false;
};

onMounted(async () => {
  initializeFromQuery();
  isLoading.value = true;
  try {
    await Promise.all([
      fetchStudents(),
      fetchSettings()
    ]);
    organizeStudentsByFamily();
  } catch (error) {
      console.error("Error during initial data fetch:", error);
  } finally {
      isLoading.value = false;
      isInitialized.value = true;
  }
});
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
</style>