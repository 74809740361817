<template>
  <div class="bg-white pb-24 sm:pb-32">
    <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:pr-4">
          <div class="relative overflow-hidden rounded-3xl bg-gray-900 shadow-2xl lg:max-w-lg">
            <iframe width="512" height="768" src="https://embed.windy.com/embed2.html?lat=-17.902&lon=168.338&detailLat=-17.757&detailLon=166.657&width=512&height=768&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1" frameborder="0"></iframe>
          </div>
        </div>
        <div>
          <div class="text-base leading-7 text-gray-700 lg:max-w-lg">
            <p class="text-base font-semibold leading-7 text-indigo-600">Safe Choices, Informed Steps</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Cyclone and Natural Disaster Preparedness</h1>
            <div class="max-w-xl">
              <p class="mt-6">Vanuatu is susceptible to natural events. At PVIS, we prioritize our community's safety with comprehensive plans and procedures for responding to significant natural disasters, including cyclones and tsunamis.</p>
              <p class="mt-8">During the cyclone season from November to May, our safety measures align with advisories from the National Disaster Management Office (NDMO). We take decisive action based on the alert levels issued, ensuring the safety of our students and staff.
            </p>
              <p class="mt-8">In the event of a tsunami warning, we have a clear evacuation plan to the University of the South Pacific (USP). "If the earthquake is long and strong, then get gone" is our motto, guiding immediate and proactive evacuation measures.
            </p>
            <p class="mt-8">Keeping our records current is crucial for emergency response. We request that parents update contact information promptly to ensure swift communication in any crisis.
            </p>
            <p class="mt-8">
            Regular drills and first aid training for staff are part of our commitment to preparedness. These practices are essential for ensuring that everyone knows how to act swiftly and safely during emergencies.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto mt-16 max-w-7xl px-6 lg:px-8">
      <h1 class="my-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Useful Resources</h1>

      <div class="mx-auto max-w-2xl lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="flex flex-col">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              {{ feature.name }}
            </dt>
            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">{{ feature.description }}</p>
              <p class="mt-2">
                <a :href="feature.href" class="text-sm font-semibold leading-6 text-indigo-600">Learn more <span aria-hidden="true">→</span></a>
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { InboxIcon, TrashIcon, UsersIcon } from '@heroicons/vue/24/outline'

const features = [
  {
    name: 'Vanuatu Meterology and Geohazards Department (VMGD)',
    description:
      "Official source for Vanuatu's weather forecasts, warnings, and geological hazard information. Stay updated on local conditions and alerts.",
    href: 'https://www.vmgd.gov.vu/vmgd/index.php',
    icon: InboxIcon,
  },
  {
    name: 'VMGD Daily weather observations',
    description:
      'Access the latest daily weather reports and observations provided by VMGD. Useful for detailed local weather conditions and planning.',
    href: 'https://www.vmgd.gov.vu/vmgd/index.php/forecast-division/public-forecast/media',
    icon: UsersIcon,
  },
  {
    name: 'VMGD Cyclone Tracking Map',
    description:
      "Monitor active tropical cyclones in the region with VMGD's official tracking map. Essential for tracking storm paths near Vanuatu.",
    href: 'https://www.vmgd.gov.vu/vmgd/index.php/forecast-division/tropical-cyclone/vanuatu-cyclone-tracking-map',
    icon: TrashIcon,
  },
  {
    name: 'Joint Typhoon Warning Center',
    description:
      'US military source providing tropical cyclone warnings for the Pacific and Indian Oceans. Offers additional storm tracking perspectives.',
    href: 'https://www.metoc.navy.mil/jtwc/jtwc.html',
    icon: TrashIcon,
  },
  {
    name: 'Get Tsunami Ready (NZ)',
    description:
      'New Zealand Civil Defence resource on tsunami preparedness. Learn how to prepare for and respond to a tsunami event effectively.',
    href: 'https://www.civildefence.govt.nz/get-ready/get-tsunami-ready/',
    icon: TrashIcon,
  },
  {
    name: 'US Tsunami Warning System',
    description:
      'Provides official tsunami warnings and information for the U.S. and globally. Includes data on recent seismic events.',
    href: 'https://www.tsunami.gov/',
    icon: TrashIcon,
  },
  {
    name: 'Joint Australian Tsunami Warning Centre',
    description:
      'Australian government service issuing tsunami warnings. Offers alerts and information relevant to the wider Pacific region.',
    href: 'http://www.bom.gov.au/tsunami/',
    icon: TrashIcon,
  },
  {
    name: 'Earthquakes @GA',
    description:
      'Monitor recent earthquake activity in the Australian region and globally. Provides data on location, magnitude, and potential impacts.',
    href: 'https://earthquakes.ga.gov.au/',
    icon: TrashIcon,
  },
  {
    name: 'Australian Bureau of Meteorology satellite weather',
    description:
      "View satellite imagery from Australia's BOM. Useful for visualizing weather patterns, cloud cover, and storm systems in the region.",
    href: 'http://satview.bom.gov.au/',
    icon: TrashIcon,
  },
]



</script>