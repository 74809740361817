<template>
  <div class="min-h-screen bg-gray-50">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
      <!-- Header -->
      <div class="mb-8">
        <a href="/billing/dashboard" class="inline-flex items-center text-sm font-semibold text-indigo-600 hover:text-indigo-500">
          <svg class="mr-1 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          Back to Dashboard
        </a>
        <h1 class="mt-4 text-2xl font-bold text-gray-900">Billing Settings</h1>
        <p class="mt-2 text-sm text-gray-600">Configure your billing settings and fee structure.</p>
      </div>

      <!-- Main Form -->
      <div class="bg-white shadow rounded-lg">
        <form @submit.prevent="submitForm" class="space-y-8 p-6">
          <!-- Basic Settings -->
          <div class="space-y-6">
            <div>
              <h2 class="text-lg font-medium text-gray-900">Basic Settings</h2>
              <p class="mt-1 text-sm text-gray-500">Configure your basic billing information.</p>
            </div>
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label for="billingYear" class="block text-sm font-medium text-gray-700">Billing Year</label>
                <input type="number" id="billingYear" v-model="billingYear" placeholder="YYYY" min="2000" max="2099" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              </div>
              <div>
                <label for="paymentTerm" class="block text-sm font-medium text-gray-700">Payment Term (days)</label>
                <select id="paymentTerm" v-model="paymentTerm" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <option value="14">14 days</option>
                  <option value="30">30 days</option>
                </select>
              </div>
            </div>
          </div>

          <!-- EEC Fees -->
          <div class="space-y-6">
            <div>
              <h2 class="text-lg font-medium text-gray-900">EEC Fees</h2>
              <p class="mt-1 text-sm text-gray-500">Configure fees for Early Education Centre programs.</p>
            </div>
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-3">
              <div>
                <label for="fullElp" class="block text-sm font-medium text-gray-700">Full ELP</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">VUV</span>
                  </div>
                  <input type="number" id="fullElp" v-model="fees.eec.fullElp" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>
              <div>
                <label for="fiveMornings" class="block text-sm font-medium text-gray-700">Five Mornings</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">VUV</span>
                  </div>
                  <input type="number" id="fiveMornings" v-model="fees.eec.fiveMornings" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>
              <div>
                <label for="threeMornings" class="block text-sm font-medium text-gray-700">Three Mornings</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">VUV</span>
                  </div>
                  <input type="number" id="threeMornings" v-model="fees.eec.threeMornings" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>
            </div>
          </div>

          <!-- School Level Fees -->
          <div class="space-y-6">
            <div>
              <h2 class="text-lg font-medium text-gray-900">School Level Fees</h2>
              <p class="mt-1 text-sm text-gray-500">Configure fees for different school levels and student categories.</p>
            </div>
            <div v-for="level in ['junior', 'middle', 'senior']" :key="level" class="space-y-4">
              <h3 class="text-md font-medium text-gray-700 capitalize">{{ level }} School</h3>
              <div class="grid grid-cols-1 gap-6 sm:grid-cols-3">
                <div>
                  <label :for="level + 'Corporate'" class="block text-sm font-medium text-gray-700">Corporate</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span class="text-gray-500 sm:text-sm">VUV</span>
                    </div>
                    <input type="number" :id="level + 'Corporate'" v-model="fees[level].corporate" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  </div>
                </div>
                <div>
                  <label :for="level + 'NonCitizen'" class="block text-sm font-medium text-gray-700">Non-Citizen</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span class="text-gray-500 sm:text-sm">VUV</span>
                    </div>
                    <input type="number" :id="level + 'NonCitizen'" v-model="fees[level].nonCitizen" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  </div>
                </div>
                <div>
                  <label :for="level + 'Citizen'" class="block text-sm font-medium text-gray-700">Citizen</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span class="text-gray-500 sm:text-sm">VUV</span>
                    </div>
                    <input type="number" :id="level + 'Citizen'" v-model="fees[level].citizen" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Additional Fees -->
          <div class="space-y-6">
            <div>
              <h2 class="text-lg font-medium text-gray-900">Additional Fees</h2>
              <p class="mt-1 text-sm text-gray-500">Configure additional fees and levies.</p>
            </div>
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label for="saceLevy1" class="block text-sm font-medium text-gray-700">SACE Levy 1</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">VUV</span>
                  </div>
                  <input type="number" id="saceLevy1" v-model="fees.saceLevy.levy1" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>
              <div>
                <label for="saceLevy2" class="block text-sm font-medium text-gray-700">SACE Levy 2</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">VUV</span>
                  </div>
                  <input type="number" id="saceLevy2" v-model="fees.saceLevy.levy2" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>
              <div>
                <label for="buildingLevy" class="block text-sm font-medium text-gray-700">Building Levy (Corporate)</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">VUV</span>
                  </div>
                  <input type="number" id="buildingLevy" v-model="fees.buildingLevy.corporate" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>
              <div>
                <label for="littleExplorer" class="block text-sm font-medium text-gray-700">Little Explorer (per term)</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">VUV</span>
                  </div>
                  <input type="number" id="littleExplorer" v-model="fees.littleExplorer.perTerm" class="block w-full pl-12 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>
            </div>
          </div>

          <!-- Discounts -->
          <div class="space-y-6">
            <div>
              <h2 class="text-lg font-medium text-gray-900">Discounts</h2>
              <p class="mt-1 text-sm text-gray-500">Configure discount percentages (enter as decimals, e.g., 0.5 for 50%).</p>
            </div>
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label for="staffDiscountOneParent" class="block text-sm font-medium text-gray-700">Staff Discount (1 Parent) %</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="number" id="staffDiscountOneParent" v-model="fees.discounts.staffDiscountOneParent" step="0.01" min="0" max="1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">%</span>
                  </div>
                </div>
              </div>
              <div>
                <label for="staffDiscountTwoParents" class="block text-sm font-medium text-gray-700">Staff Discount (2 Parents) %</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="number" id="staffDiscountTwoParents" v-model="fees.discounts.staffDiscountTwoParents" step="0.01" min="0" max="1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">%</span>
                  </div>
                </div>
              </div>
              <div>
                <label for="bursaryDiscount" class="block text-sm font-medium text-gray-700">Bursary Discount %</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="number" id="bursaryDiscount" v-model="fees.discounts.bursaryDiscount" step="0.01" min="0" max="1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">%</span>
                  </div>
                </div>
              </div>
              <div>
                <label for="scholarshipDiscount" class="block text-sm font-medium text-gray-700">Scholarship Discount %</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="number" id="scholarshipDiscount" v-model="fees.discounts.scholarshipDiscount" step="0.01" min="0" max="1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">%</span>
                  </div>
                </div>
              </div>
              <div>
                <label for="familySizeDiscount" class="block text-sm font-medium text-gray-700">Family Size Discount (4+) %</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input type="number" id="familySizeDiscount" v-model="fees.discounts.familySize" step="0.01" min="0" max="1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                  <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end space-x-3">
            <button type="button" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Cancel
            </button>
            <button type="submit" class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save Settings
            </button>
          </div>
        </form>

        <!-- Fee Simulator Section -->
        <FeeSimulator :fees="fees" v-if="fees" />

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import FeeSimulator from '../components/FeeSimulator.vue';

const paymentTerm = ref('');
const billingYear = ref('');
const fees = ref({
  eec: {
    fullElp: 0,
    fiveMornings: 0,
    threeMornings: 0
  },
  junior: {
    corporate: 0,
    nonCitizen: 0,
    citizen: 0
  },
  middle: {
    corporate: 0,
    nonCitizen: 0,
    citizen: 0
  },
  senior: {
    corporate: 0,
    nonCitizen: 0,
    citizen: 0
  },
  saceLevy: {
    levy1: 0,
    levy2: 0
  },
  buildingLevy: {
    corporate: 0
  },
  littleExplorer: {
    perTerm: 0
  },
  discounts: {
    staffDiscountOneParent: 0,
    staffDiscountTwoParents: 0,
    familySize: 0,
    bursaryDiscount: 0,
    scholarshipDiscount: 0
  }
});

const submitForm = async () => {
  const settingsData = {
    paymentTerm: paymentTerm.value,
    billingYear: billingYear.value,
    fees: fees.value
  };

  try {
    const response = await axios.post('/api/updateSettings', settingsData);
    console.log(response.data.message);
  } catch (error) {
    console.error('Error updating settings:', error);
  }
};

const fetchSettings = async () => {
  try {
    const response = await axios.get('/api/getSettings');
    if (response.data) {
      paymentTerm.value = response.data.paymentTerm;
      billingYear.value = response.data.billingYear;
      fees.value = response.data.fees;
    }
  } catch (error) {
    console.error('Error fetching settings:', error);
  }
};

onMounted(fetchSettings);
</script>