<template>
  <div class="mt-12 p-6 bg-gray-100 rounded-lg shadow-inner">
    <h2 class="text-xl font-semibold text-gray-800 mb-4">Fee Simulator</h2>
    <p class="text-sm text-gray-600 mb-6">
      Preview how fee changes affect different family scenarios based on the settings above.
      The simulation updates automatically as you modify the fees.
    </p>

    <div v-if="!fees || !calculatedScenarios.length">
      <p class="text-gray-500">Loading fee data or calculating scenarios...</p>
    </div>
    <div v-else>
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Scenario Description</th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 1</th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 2</th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 3</th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 4</th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Yearly Total</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr v-for="(scenario, index) in calculatedScenarios" :key="index">
            <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ scenario.description }}</td>
            <td class="px-3 py-4 text-right text-sm text-gray-500">{{ formatCurrency(scenario.termTotals[0]) }}</td>
            <td class="px-3 py-4 text-right text-sm text-gray-500">{{ formatCurrency(scenario.termTotals[1]) }}</td>
            <td class="px-3 py-4 text-right text-sm text-gray-500">{{ formatCurrency(scenario.termTotals[2]) }}</td>
            <td class="px-3 py-4 text-right text-sm text-gray-500">{{ formatCurrency(scenario.termTotals[3]) }}</td>
            <td class="px-3 py-4 text-right text-sm font-semibold text-gray-700">{{ formatCurrency(scenario.yearlyTotal) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, watch } from 'vue';
import { formatCurrency, getSchoolLevel } from '../utils/feeUtils';

const props = defineProps({
  fees: {
    type: Object,
    required: true
  }
});

// --- Calculation Logic (adapted for simulation) ---

const calculateSimulatedBaseTuition = (student) => {
  if (!props.fees) return 0;
  const localFees = props.fees; // Use fees from props
  let fee = 0;
  const schoolLevel = getSchoolLevel(student.currentYear);
  // Simulate status based on properties, assuming no family history for simplicity in simulation
  const status = student.debenturesCorp ? 'Corporate' : 
                 student.primaryCitizenship === 'Vanuatu' ? 'Citizen/Long-Term' : 'Non-Citizen'; 

  if (schoolLevel === 'EEC') {
    switch (student.feeComponent) {
      case 'Full ELP': fee = localFees.eec.fullElp; break;
      case 'Five Mornings': fee = localFees.eec.fiveMornings; break;
      case 'Three Mornings': fee = localFees.eec.threeMornings; break;
    }
  } else {
    switch (schoolLevel) {
      case 'Junior':
        fee = status === 'Corporate' ? localFees.junior.corporate : 
              status === 'Non-Citizen' ? localFees.junior.nonCitizen : 
              localFees.junior.citizen;
        break;
      case 'Middle':
        fee = status === 'Corporate' ? localFees.middle.corporate : 
              status === 'Non-Citizen' ? localFees.middle.nonCitizen : 
              localFees.middle.citizen;
        break;
      case 'Senior':
        fee = status === 'Corporate' ? localFees.senior.corporate : 
              status === 'Non-Citizen' ? localFees.senior.nonCitizen : 
              localFees.senior.citizen;
        break;
    }
  }
  return Math.round(fee);
};

const calculateSimulatedYearlyFee = (student) => {
  if (!props.fees || !props.fees.discounts) return 0;
  const localFees = props.fees;
  
  let baseFee = calculateSimulatedBaseTuition(student);
  let additions = 0;

  if (student.saceLevy1) additions += localFees.saceLevy.levy1;
  else if (student.saceLevy2) additions += localFees.saceLevy.levy2;

  const status = student.debenturesCorp ? 'Corporate' : 
                 student.primaryCitizenship === 'Vanuatu' ? 'Citizen/Long-Term' : 'Non-Citizen';
  if (status === 'Corporate') {
      additions += localFees.buildingLevy.corporate;
  }
  
  if (student.littleExplorer) {
      additions += localFees.littleExplorer.perTerm * 4;
  }

  let totalFee = baseFee + additions;
  let discountRate = 0;

  // Determine highest applicable discount rate (Corrected Logic)
  if (student.isScholarship) {
      discountRate = localFees.discounts.scholarshipDiscount;
  } else if (student.isBursary) {
      discountRate = localFees.discounts.bursaryDiscount;
  } else if (student.staffChildTwoParents) {
      discountRate = localFees.discounts.staffDiscountTwoParents;
  } else if (student.staffChildOneParent) {
      discountRate = localFees.discounts.staffDiscountOneParent;
  }
  // Removed fallback to old staffChild flag for clarity in simulation

  discountRate = typeof discountRate === 'number' && discountRate >= 0 && discountRate <= 1 ? discountRate : 0;
  totalFee *= (1 - discountRate);

  return Math.round(totalFee);
};

const calculateSimulatedTermFee = (student, term = 1) => {
  if (!props.fees || !props.fees.discounts) return 0;
  const localFees = props.fees;
  
  let baseTermFee = calculateSimulatedBaseTuition(student) / 4;
  let termAdditions = 0;

  if (term === 1) {
    if (student.saceLevy1) termAdditions += localFees.saceLevy.levy1;
    else if (student.saceLevy2) termAdditions += localFees.saceLevy.levy2;
  }

  const status = student.debenturesCorp ? 'Corporate' : 
                 student.primaryCitizenship === 'Vanuatu' ? 'Citizen/Long-Term' : 'Non-Citizen';
  if (status === 'Corporate') {
      termAdditions += localFees.buildingLevy.corporate / 4;
  }
  
  if (student.littleExplorer) {
      // Use props.fees directly here as calculateLittleExplorerTermFee is simple
      termAdditions += localFees.littleExplorer.perTerm || 0;
  }

  let termTotal = baseTermFee + termAdditions;
  let discountRate = 0;

  // Determine highest discount rate for the term (Corrected Logic)
  if (student.isScholarship) {
      discountRate = localFees.discounts.scholarshipDiscount;
  } else if (student.isBursary) {
      discountRate = localFees.discounts.bursaryDiscount;
  } else if (student.staffChildTwoParents) { 
      discountRate = localFees.discounts.staffDiscountTwoParents;
  } else if (student.staffChildOneParent) { 
      discountRate = localFees.discounts.staffDiscountOneParent;
  }
  // Removed fallback to old staffChild flag
    
  discountRate = typeof discountRate === 'number' && discountRate >= 0 && discountRate <= 1 ? discountRate : 0;
  termTotal *= (1 - discountRate);

  return Math.round(termTotal);
};

// --- Scenario Definitions ---
const baseScenarios = [
  // 1 Child Scenarios (Add new flags)
  { description: '1 Junior Citizen Child', students: [{ currentYear: 'Year 3', primaryCitizenship: 'Vanuatu' }] },
  { description: '1 Senior Non-Citizen Child', students: [{ currentYear: 'Year 10', primaryCitizenship: 'Australia' }] },
  { description: '1 Middle Corporate Child', students: [{ currentYear: 'Year 7', debenturesCorp: true }] },
  { description: '1 EEC Full ELP Child', students: [{ currentYear: 'PRE', feeComponent: 'Full ELP' }] },
  { description: '1 Senior Citizen Child (SACE 1)', students: [{ currentYear: 'Year 11', primaryCitizenship: 'Vanuatu', saceLevy1: true }] },
  { description: '1 Junior Citizen Child (Staff 1 Parent)', students: [{ currentYear: 'Year 3', primaryCitizenship: 'Vanuatu', staffChildOneParent: true }] },
  { description: '1 Junior Citizen Child (Staff 2 Parents)', students: [{ currentYear: 'Year 3', primaryCitizenship: 'Vanuatu', staffChildTwoParents: true }] },
  { description: '1 Junior Citizen Child (Bursary)', students: [{ currentYear: 'Year 3', primaryCitizenship: 'Vanuatu', isBursary: true }] },
  { description: '1 Junior Citizen Child (Scholarship)', students: [{ currentYear: 'Year 3', primaryCitizenship: 'Vanuatu', isScholarship: true }] },
  { description: '1 EEC Full ELP Child (Little Explorer)', students: [{ currentYear: 'PRE', feeComponent: 'Full ELP', littleExplorer: true }] },

  // 2 Children Scenarios
  { description: '2 Children (Junior Citizen, Senior Non-Citizen)', students: [
      { currentYear: 'Year 4', primaryCitizenship: 'Vanuatu' }, 
      { currentYear: 'Year 10', primaryCitizenship: 'Australia' }
  ]},
  { description: '2 Children (Junior Staff 1P, Middle Staff 1P)', students: [
      { currentYear: 'Year 2', primaryCitizenship: 'Vanuatu', staffChildOneParent: true }, 
      { currentYear: 'Year 6', primaryCitizenship: 'Vanuatu', staffChildOneParent: true }
  ]},
  { description: '2 Children (Junior Bursary, Middle Citizen)', students: [
      { currentYear: 'Year 2', primaryCitizenship: 'Vanuatu', isBursary: true }, 
      { currentYear: 'Year 6', primaryCitizenship: 'Vanuatu' }
  ]},
  
  // 4 Children Scenarios (Family Discount Applies)
  { description: '4 Children (All Citizen)', students: [
      { currentYear: 'Year 1', primaryCitizenship: 'Vanuatu' }, 
      { currentYear: 'Year 3', primaryCitizenship: 'Vanuatu' },
      { currentYear: 'Year 6', primaryCitizenship: 'Vanuatu' },
      { currentYear: 'Year 9', primaryCitizenship: 'Vanuatu' }
  ]},
  { description: '4 Children (All Citizen, Staff 1P)', students: [
      { currentYear: 'Year 1', primaryCitizenship: 'Vanuatu', staffChildOneParent: true }, 
      { currentYear: 'Year 3', primaryCitizenship: 'Vanuatu', staffChildOneParent: true },
      { currentYear: 'Year 6', primaryCitizenship: 'Vanuatu', staffChildOneParent: true },
      { currentYear: 'Year 9', primaryCitizenship: 'Vanuatu', staffChildOneParent: true }
  ]},
  { description: '4 Children (All Citizen, 1 Scholarship)', students: [
      { currentYear: 'Year 1', primaryCitizenship: 'Vanuatu', isScholarship: true }, 
      { currentYear: 'Year 3', primaryCitizenship: 'Vanuatu' },
      { currentYear: 'Year 6', primaryCitizenship: 'Vanuatu' },
      { currentYear: 'Year 9', primaryCitizenship: 'Vanuatu' }
  ]},
];

// --- Computed Property for Calculated Scenarios ---
const calculatedScenarios = computed(() => {
  if (!props.fees || !props.fees.discounts) return []; // Ensure fees and discounts are loaded
  const localFees = props.fees;

  return baseScenarios.map(scenario => {
    let yearlySubtotal = 0;
    let termSubtotals = [0, 0, 0, 0];

    scenario.students.forEach(student => {
      yearlySubtotal += calculateSimulatedYearlyFee(student);
      termSubtotals[0] += calculateSimulatedTermFee(student, 1);
      termSubtotals[1] += calculateSimulatedTermFee(student, 2);
      termSubtotals[2] += calculateSimulatedTermFee(student, 3);
      termSubtotals[3] += calculateSimulatedTermFee(student, 4);
    });

    let finalYearlyTotal = yearlySubtotal;
    let finalTermTotals = [...termSubtotals];

    // Apply family size discount if applicable (AFTER staff discount)
    if (scenario.students.length >= 4) {
      const familyDiscountRate = 1 - localFees.discounts.familySize;
      finalYearlyTotal *= familyDiscountRate;
      finalTermTotals = finalTermTotals.map(termTotal => termTotal * familyDiscountRate);
    }

    return {
      ...scenario,
      termTotals: finalTermTotals.map(t => Math.round(t)),
      yearlyTotal: Math.round(finalYearlyTotal)
    };
  });
});

// Watch for changes in fees (Optional: computed handles reactivity, but good for debugging)
watch(() => props.fees, (newFees) => {
  console.log('Simulator detected fee changes:', newFees);
}, { deep: true });

</script> 