// Utility functions for fee calculations
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-VU', {
    style: 'currency',
    currency: 'VUV',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};

export const getSchoolLevel = (year) => {
  if (['PRE'].includes(year)) {
    return 'EEC';
  }
  if (year === 'FND' || (year?.startsWith('Year ') && parseInt(year.replace('Year ', '')) <= 4)) {
    return 'Junior';
  }
  const yearNumber = parseInt(year?.replace('Year ', ''));
  if (yearNumber >= 5 && yearNumber <= 8) {
    return 'Middle';
  }
  if (yearNumber >= 9 && yearNumber <= 12) {
    return 'Senior';
  }
  return '';
};

export const getStudentStatus = (student, allFamilyStudents = []) => {
  // If student is corporate, this takes precedence over everything
  if (student.debenturesCorp) {
    return 'Corporate';
  }
  
  // Check if any student in the family has Citizen/Long-Term status
  const hasLongTermInFamily = allFamilyStudents.some(familyStudent => {
    const startDate = new Date(familyStudent.firstAttendanceDate);
    const endDate = new Date();
    let years = 0;

    if (!isNaN(startDate.getTime())) {
      years = endDate.getFullYear() - startDate.getFullYear();
      if (endDate.getMonth() < startDate.getMonth() || 
          (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
        years--;
      }
    }

    return familyStudent.primaryCitizenship === 'Vanuatu' || years > 2;
  });

  // If any family member is Citizen/Long-Term, apply it to this student
  if (hasLongTermInFamily) {
    return 'Citizen/Long-Term';
  }

  // Individual student check (fallback if no family member is long-term)
  const startDate = new Date(student.firstAttendanceDate);
  const endDate = new Date();
  let years = 0;

  if (!isNaN(startDate.getTime())) {
    years = endDate.getFullYear() - startDate.getFullYear();
    if (endDate.getMonth() < startDate.getMonth() || 
        (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
      years--;
    }
  }
  
  if (student.primaryCitizenship === 'Vanuatu' || years > 2) {
    return 'Citizen/Long-Term';
  }
  
  return 'Non-Citizen';
}; 