<template>
  <div class="p-4">
    <!-- Fee table -->
    <table class="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Fee Type</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 1</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 2</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 3</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Term 4</th>
          <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">Yearly Total</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <!-- Row for each student -->
        <template v-for="student in students" :key="student._id">
          <tr>
            <td class="py-2 text-sm text-right text-gray-600">Tuition Fee for {{ student.firstNameLegal }} {{ student.lastNameLegal }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateBaseTuition(student, students) / 4) }}</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(calculateBaseTuition(student, students)) }}</td>
          </tr>
          <!-- SACE Levy row if applicable -->
          <tr v-if="student.saceLevy1 || student.saceLevy2">
            <td class="py-2 pl-8 text-sm text-right text-gray-600">{{ student.saceLevy1 ? 'SACE Levy 1' : 'SACE Levy 2' }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(student.saceLevy1 ? (settings?.fees?.saceLevy?.levy1 ?? 0) : (student.saceLevy2 ? (settings?.fees?.saceLevy?.levy2 ?? 0) : 0)) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">0</td>
            <td class="py-2 text-right text-sm text-gray-600">0</td>
            <td class="py-2 text-right text-sm text-gray-600">0</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(student.saceLevy1 ? (settings?.fees?.saceLevy?.levy1 ?? 0) : (student.saceLevy2 ? (settings?.fees?.saceLevy?.levy2 ?? 0) : 0)) }}</td>
          </tr>
          <!-- Building Levy row if corporate -->
          <tr v-if="getStudentStatus(student, students) === 'Corporate'">
            <td class="py-2 pl-8 text-sm text-right text-gray-600">Building Levy</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency((settings?.fees?.buildingLevy?.corporate ?? 0) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency((settings?.fees?.buildingLevy?.corporate ?? 0) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency((settings?.fees?.buildingLevy?.corporate ?? 0) / 4) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency((settings?.fees?.buildingLevy?.corporate ?? 0) / 4) }}</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(settings?.fees?.buildingLevy?.corporate ?? 0) }}</td>
          </tr>
          <!-- Little Explorer Fee row if applicable -->
          <tr v-if="student.littleExplorer">
            <td class="py-2 pl-8 text-sm text-right text-gray-600">Little Explorer Program</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm text-gray-600">{{ formatCurrency(calculateLittleExplorerTermFee()) }}</td>
            <td class="py-2 text-right text-sm font-medium text-gray-900">{{ formatCurrency(calculateLittleExplorerTermFee() * 4) }}</td>
          </tr>
          <!-- Discount Row (Dynamically calculated) -->
          <template v-if="getApplicableDiscount(student).rate > 0">
            <tr>
              <td class="py-2 pl-8 text-sm text-right text-gray-600">{{ getApplicableDiscount(student).name }}</td>
              <td v-for="term in [1, 2, 3, 4]" :key="term" class="py-2 text-right text-sm text-red-600">
                <!-- Calculate discount based on *discountable* term total -->
                {{ formatCurrency(Math.round(-calculateDiscountableTermFee(student, term) * getApplicableDiscount(student).rate)) }}
              </td>
              <td class="py-2 text-right text-sm font-medium text-red-600">
                <!-- Calculate discount based on *discountable* yearly total -->
                {{ formatCurrency(Math.round(-calculateDiscountableYearlyFee(student) * getApplicableDiscount(student).rate)) }}
              </td>
            </tr>
          </template>
        </template>
        <!-- Family Total section -->
        <template v-if="students.length >= 4">
          <tr class="border-t-2 border-gray-400">
            <td class="py-2 text-right text-sm font-bold text-gray-900">Family Subtotal</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(1)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(2)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(3)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyTotal(4)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFamilyYearlyTotal()) }}</td>
          </tr>
          <!-- Family Size Discount row -->
          <tr>
            <td class="py-2 text-right text-sm font-bold text-gray-600">Family Size Discount ({{ Math.round((settings?.fees?.discounts?.familySize ?? 0) * 100) }}%)</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(1) * (settings?.fees?.discounts?.familySize ?? 0)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(2) * (settings?.fees?.discounts?.familySize ?? 0)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(3) * (settings?.fees?.discounts?.familySize ?? 0)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyTotal(4) * (settings?.fees?.discounts?.familySize ?? 0)) }}</td>
            <td class="py-2 text-right text-sm font-bold text-red-600">{{ formatCurrency(-calculateFamilyYearlyTotal() * (settings?.fees?.discounts?.familySize ?? 0)) }}</td>
          </tr>
        </template>
        <!-- Final Total row -->
        <tr class="border-t border-gray-300">
          <td class="py-2 text-right text-sm font-bold text-gray-900">Family Total</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(1)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(2)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(3)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyTotal(4)) }}</td>
          <td class="py-2 text-right text-sm font-bold text-gray-900">{{ formatCurrency(calculateFinalFamilyYearlyTotal()) }}</td>
        </tr>
        <!-- Invoice Generation row -->
        <tr>
          <td class="py-2 text-right text-sm font-semibold text-gray-900">Invoice Generation</td>
          <td v-for="term in [1, 2, 3, 4]" :key="term" class="py-2 text-right">
            <XeroInvoiceGenerator
              :family-code="familyCode"
              :term="term"
              :students="students"
              :term-total="calculateFinalFamilyTotal(term)"
              :invoice_id="students.find(s => s.Invoices && s.Invoices[`Term${term}${new Date().getFullYear()}Invoice`])?.Invoices[`Term${term}${new Date().getFullYear()}Invoice`]"
              :is-edit-mode="isEditMode"
              @invoice-generated="handleInvoiceGenerated"
              @invoice-voided="handleInvoiceVoided"
              class="text-xs inline-block"
            />
          </td>
          <td class="py-2 text-right">
            <button
              @click="isEditMode = !isEditMode"
              class="text-xs font-semibold rounded-md px-2 py-1 ring-1 ring-inset"
              :class="{
                'text-indigo-600 hover:text-indigo-500 ring-indigo-600/20 hover:bg-indigo-50': !isEditMode,
                'text-red-600 hover:text-red-500 ring-red-600/20 hover:bg-red-50': isEditMode
              }">
              {{ isEditMode ? 'Done' : 'Edit' }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import XeroInvoiceGenerator from './XeroInvoiceGenerator.vue';
import { formatCurrency, getSchoolLevel, getStudentStatus } from '../utils/feeUtils';

const props = defineProps({
  students: {
    type: Array,
    required: true
  },
  familyCode: {
    type: String,
    required: true
  },
  invoices: {
    type: Object,
    default: () => ({})
  },
  generatingInvoice: {
    type: Boolean,
    default: false
  },
  settings: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['invoice-generated', 'invoice-voided']);

const handleInvoiceGenerated = (data) => {
  emit('invoice-generated', data);
};

const handleInvoiceVoided = (data) => {
  emit('invoice-voided', data);
};

const calculateBaseTuition = (student, allFamilyStudents) => {
  if (!props.settings || !props.settings.fees) return 0;
  const localFees = props.settings.fees;
  
  let fee = 0;
  const schoolLevel = getSchoolLevel(student.currentYear);
  const status = getStudentStatus(student, allFamilyStudents);

  if (schoolLevel === 'EEC') {
    switch (student.feeComponent) {
      case 'Full ELP': fee = localFees.eec.fullElp; break;
      case 'Five Mornings': fee = localFees.eec.fiveMornings; break;
      case 'Three Mornings': fee = localFees.eec.threeMornings; break;
      default: fee = 0; // Handle missing component
    }
  } else {
    switch (schoolLevel) {
      case 'Junior':
        fee = status === 'Corporate' ? localFees.junior.corporate : 
              status === 'Non-Citizen' ? localFees.junior.nonCitizen : 
              localFees.junior.citizen;
        break;
      case 'Middle':
        fee = status === 'Corporate' ? localFees.middle.corporate : 
              status === 'Non-Citizen' ? localFees.middle.nonCitizen : 
              localFees.middle.citizen;
        break;
      case 'Senior':
        fee = status === 'Corporate' ? localFees.senior.corporate : 
              status === 'Non-Citizen' ? localFees.senior.nonCitizen : 
              localFees.senior.citizen;
        break;
      default: fee = 0;
    }
  }
  return Math.round(fee);
};

// Helper to get applicable discount details
const getApplicableDiscount = (student) => {
  if (!props.settings || !props.settings.fees || !props.settings.fees.discounts) {
    return { name: '', rate: 0 };
  }
  const discounts = props.settings.fees.discounts;
  let name = '';
  let rate = 0;

  // Priority: Scholarship > Bursary > Staff 2 Parent > Staff 1 Parent
  if (student.isScholarship) {
      rate = discounts.scholarshipDiscount;
      name = 'Scholarship';
  } else if (student.isBursary) {
      rate = discounts.bursaryDiscount;
      name = 'Bursary';
  } else if (student.staffChildTwoParents) {
      rate = discounts.staffDiscountTwoParents;
      name = 'Staff Discount (2 Parents)';
  } else if (student.staffChildOneParent) {
      rate = discounts.staffDiscountOneParent;
      name = 'Staff Discount (1 Parent)';
  }
  // Removed fallback to old staffChild flag

  rate = typeof rate === 'number' && rate >= 0 && rate <= 1 ? rate : 0;
  const percentage = Math.round(rate * 100); // For display

  // Return name only if rate > 0
  return { name: rate > 0 ? `${name} (${percentage}%)` : '', rate };
};

// Helper to calculate the fee amount *eligible* for discount for a term
const calculateDiscountableTermFee = (student, term) => {
    if (!props.settings || !props.settings.fees) return 0;
    const localFees = props.settings.fees;
    const allFamilyStudents = props.students;

    let baseTermFee = calculateBaseTuition(student, allFamilyStudents) / 4;
    let termAdditions = 0;

    // Only include discountable additions
    if (term === 1) {
      if (student.saceLevy1) termAdditions += localFees.saceLevy.levy1;
      else if (student.saceLevy2) termAdditions += localFees.saceLevy.levy2;
    }

    if (getStudentStatus(student, allFamilyStudents) === 'Corporate') {
      termAdditions += localFees.buildingLevy.corporate / 4;
    }

    // Exclude Little Explorer

    return baseTermFee + termAdditions;
};

// Helper to calculate the fee amount *eligible* for discount for the year
const calculateDiscountableYearlyFee = (student) => {
    if (!props.settings || !props.settings.fees) return 0;
    const localFees = props.settings.fees;
    const allFamilyStudents = props.students;

    let baseFee = calculateBaseTuition(student, allFamilyStudents);
    let additions = 0;

    // Only include discountable additions
    if (student.saceLevy1) additions += localFees.saceLevy.levy1;
    else if (student.saceLevy2) additions += localFees.saceLevy.levy2;

    if (getStudentStatus(student, allFamilyStudents) === 'Corporate') {
        additions += localFees.buildingLevy.corporate;
    }

    // Exclude Little Explorer

    return baseFee + additions;
};

const calculateYearlyFee = (student) => {
  if (!props.settings || !props.settings.fees) return 0;
  
  // Calculate discountable portion
  let discountableFee = calculateDiscountableYearlyFee(student);

  // Determine discount rate
  const discountInfo = getApplicableDiscount(student);
  const discountRate = discountInfo.rate;

  // Apply discount to the discountable portion
  let discountedFee = discountableFee * (1 - discountRate);

  // Add non-discountable fees (Little Explorer)
  let nonDiscountableFee = 0;
  if (student.littleExplorer) {
    nonDiscountableFee += (calculateLittleExplorerTermFee() * 4);
  }

  // Final total
  let totalFee = discountedFee + nonDiscountableFee;
  
  return Math.round(totalFee);
};

const calculateLittleExplorerTermFee = () => {
  if (!props.settings || !props.settings.fees) return 0;
  return props.settings.fees.littleExplorer.perTerm;
};

const calculateFamilyTotal = (term) => {
  if (!props.settings || !props.settings.fees) return 0;
  
  return Math.round(props.students.reduce((total, student) => {
    // Calculate discountable portion for the term
    let discountableTermFee = calculateDiscountableTermFee(student, term);

    // Determine discount rate
    const discountInfo = getApplicableDiscount(student);
    const discountRate = discountInfo.rate;

    // Apply discount to the discountable portion
    let discountedTermPortion = discountableTermFee * (1 - discountRate);

    // Add non-discountable fees for the term (Little Explorer)
    let nonDiscountableTermFee = 0;
    if (student.littleExplorer) {
        nonDiscountableTermFee += calculateLittleExplorerTermFee();
    }

    // Total for the student for this term
    let studentTermTotal = discountedTermPortion + nonDiscountableTermFee;

    return total + studentTermTotal;
  }, 0));
};

const calculateFamilyYearlyTotal = () => {
  if (!props.settings || !props.settings.fees) return 0;
  
  return Math.round(props.students.reduce((total, student) => {
    // Use the dedicated yearly fee calculation per student
    return total + calculateYearlyFee(student);
  }, 0));
};

const calculateFinalFamilyTotal = (term) => {
  if (!props.settings || !props.settings.fees || !props.settings.fees.discounts) return 0;
  
  const subtotal = calculateFamilyTotal(term);
  const discountRate = typeof props.settings.fees.discounts.familySize === 'number' && props.settings.fees.discounts.familySize >= 0 && props.settings.fees.discounts.familySize <= 1
                         ? props.settings.fees.discounts.familySize : 0;
  return Math.round(props.students.length >= 4 ? subtotal * (1 - discountRate) : subtotal);
};

const calculateFinalFamilyYearlyTotal = () => {
  if (!props.settings || !props.settings.fees || !props.settings.fees.discounts) return 0;
  
  const subtotal = calculateFamilyYearlyTotal();
  const discountRate = typeof props.settings.fees.discounts.familySize === 'number' && props.settings.fees.discounts.familySize >= 0 && props.settings.fees.discounts.familySize <= 1
                         ? props.settings.fees.discounts.familySize : 0;
  return Math.round(props.students.length >= 4 ? subtotal * (1 - discountRate) : subtotal);
};

const isEditMode = ref(false);
</script> 